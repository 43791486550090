import { ClearAll, Redo, Undo } from "@mui/icons-material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab/";
import {
    Button,
    Grid,
    IconButton,
    Tab,
    TablePagination,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BookHistoryService } from "../../services/BookHistoryService";
import { useGetAPI } from "../../services/Hooks";
import { SessionService } from "../../services/SessionService";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { baseAPI, dateFormatter } from "../constants";
import { Search } from "../Filters/Search";
import { RUDTable } from "../Table/RUDTable";

type Filter = {
    campus: number | string;
    student?: number | string;
    book?: number | string;
    issueDate?: any;
    retrunDate?: any;
    search: "";

    [key: string]: any;
};

type history = "normal" | "graphical";
const BookHistory = () => {
    const [sort, setSort] = useState<{ column: ""; direction: "" }>();
    const [activeTab, setActiveTab] = useState<history>("normal");
    const { user } = useContext(AppContext);

    const [filter, setFilter] = useState<Filter>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        session: "",
        classroom: "",
        section: "",
        student: "",
        book: "",
        issueDate: "",
        retrunDate: "",
        search: "",
        apply: 0,
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 10,
    });
    const [lastSession, setLastSession] = useState<any>();

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus:
                user?.baseUser?.userRegions?.length > 1
                    ? ""
                    : user?.baseUser?.userRegions[0]?.campus?.id,
            session: "",
            classroom: "",
            section: "",
            book: "",
            search: "",
            apply: 0,
        });
    };

    const handlePageChange = (ev: any, page: number) => {
        setPagination({ ...pagination, page: page });
    };

    const handleRowsPerPageChange = (ev: any) => {
        setPagination({ ...pagination, limit: ev.target.value });
    };

    const [history, count, status] = useGetAPI(
        `${baseAPI}/library/library-history`,
        pagination.page + 1,
        pagination.limit,
        {
            bookInstance: filter.book ? filter.book : undefined,
            column: sort?.column ? sort.column : undefined,
            issueDate: filter.issueDate ? filter.issueDate : undefined,
            returnDate: filter.retrunDate ? filter.retrunDate : undefined,
            search: filter.search ? filter.search : undefined,
            direction: sort?.direction ? sort.direction : undefined,
            campus: filter.campus ? filter.campus : undefined,
            session: filter.apply
                ? filter.session
                    ? filter.session
                    : undefined
                : undefined,
            classroom: filter.classroom ? filter.classroom : undefined,
            section: filter.section ? filter.section : undefined,
        },
        [filter.apply, pagination, sort?.direction]
    );

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: filter.campus,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: "",
                }));
                setLastSession(null);
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (filter.campus) {
            LastSession();
        }
    }, [filter.campus]);

    return (
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid item container spacing={1}>
                {user?.baseUser?.userRegions?.length > 1 && (
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        api="/org/session"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                session: c?.id || "",
                            })
                        }
                        label="Session"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                        apiParams={{
                            campus: filter.campus,
                            status: "active",
                        }}
                        defaultValue={lastSession}
                        key={lastSession?.id}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                classroom: c?.id || "",
                            })
                        }
                        label="Class"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                        apiParams={{
                            campus: filter.campus,
                            status: "active",
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        api="/org/academics/section"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                section: c?.id || "",
                            })
                        }
                        label="Section"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                        apiParams={{
                            classroom: filter.classroom,
                            status: "active",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/library/book-instance"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                book: c?.id || "",
                            })
                        }
                        label="Book"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                        processor={(opt) => ({
                            ...opt,
                            name: `BK-${opt?.id}`,
                        })}
                        apiParams={{ campus: filter.campus }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="Issue Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                issueDate: e.target.value,
                            })
                        }
                        defaultValue={new Date().toISOString().split("T")[0]}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="Return Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                retrunDate: e.target.value,
                            })
                        }
                        variant="outlined"
                        defaultValue={new Date().toISOString().split("T")[0]}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Search
                        filter={filter}
                        setFilter={setFilter}
                        clear={clearFilters}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        size="small"
                        style={{ height: "100%" }}
                    >
                        {" "}
                        Search
                    </Button>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAll />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <div>
                <Tabs
                    centered
                    textColor="primary"
                    indicatorColor="primary"
                    onChange={(e, newTab) => setActiveTab(newTab as history)}
                    value={activeTab}
                >
                    <Tab label="Normal" value="normal" />
                    <Tab label="Graphical" value="graphical" />
                </Tabs>
            </div>

            {activeTab == "normal" ? (
                <div style={{ width: "100%" }}>
                    <RUDTable
                        getter={(page, limit) =>
                            BookHistoryService.getHistory(page, limit, {
                                search: filter.search ? filter.search : null,
                                campus: filter.campus
                                    ? filter.campus
                                    : undefined,
                                session: filter.apply
                                    ? filter.session
                                        ? filter.session
                                        : undefined
                                    : undefined,
                                classroom: filter.classroom
                                    ? filter.classroom
                                    : undefined,
                                section: filter.section
                                    ? filter.section
                                    : undefined,

                                bookInstance: filter.book
                                    ? filter.book
                                    : undefined,
                                column: sort?.column ? sort.column : undefined,
                                direction: sort?.direction
                                    ? sort.direction
                                    : undefined,

                                issueDate: filter.issueDate
                                    ? filter.issueDate
                                    : undefined,
                                returnDate: filter.retrunDate
                                    ? filter.retrunDate
                                    : undefined,
                            })
                        }
                        deletor={undefined}
                        rowsPreprocessor={(row) => ({
                            ...row,
                            deletionId: row.id,
                            admissionNo: row.student.admissionNumber,
                            fileNo: row.student.fileNo,
                            enrollmentNo: row.student.enrollmentNo,
                            fatherName: row.student.fatherName,
                            bookName: row.bookInstance.book.name,
                            operatorName: row.operator.name,
                            returnDate: row.returnDate
                                ? dateFormatter.format(new Date(row.returnDate))
                                : "N/A",
                            issueDate: row.issueDate
                                ? dateFormatter.format(new Date(row.issueDate))
                                : null,
                            issueTo: row.student.baseUser.name,
                            campusName: row.student.campus.name,
                            classroomName: row.student.classroom.name,
                            sessionName: row.student.session.name,
                            sectionName: row.student.section.name,

                            acessionNo: `BK-${row.bookInstance.id}`,
                        })}
                        readables={{
                            admissionNo: "Reg No.",
                            enrollmentNo: "Enroll No",
                            fileNo: "File no",
                            issueTo: "Issue To",
                            fatherName: "Father Name",
                            acessionNo: "AcessionNo",
                            issueDate: "IssueDate",
                            returnDate: "Return Date",
                            bookName: "Book Name",
                            operatorName: "Operator Name",
                            campusName: "Campus",
                            sessionName: "Session",
                            classroomName: "Classroom",
                            sectionName: "Section",
                        }}
                        updatingAgents={[filter.apply, sort?.direction]}
                        ops={{
                            read: "READ SESSION",
                            edit: "",
                            delete: "DELETE SESSION",
                        }}
                        storageKey="history"
                        customCols={[]}
                        onSort={(row: any) => {
                            setSort(row);
                        }}
                    />
                </div>
            ) : (
                <>
                    {" "}
                    <Grid item xs={12}>
                        <Timeline position="alternate">
                            {history?.map((item: any, id: number) => (
                                <TimelineItem key={id}>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            {item.returnDate === null ? (
                                                <Redo />
                                            ) : (
                                                <Undo />
                                            )}
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>

                                    <TimelineContent>
                                        <Typography variant="h6">
                                            {item.bookInstance.book.name}
                                        </Typography>

                                        <Typography variant="body2">
                                            {item.returnDate === null
                                                ? "Issued To : " +
                                                  item.student.baseUser.name
                                                : "Returned By : " +
                                                  item.student.baseUser.name}
                                        </Typography>

                                        <Typography variant="body2">
                                            {item.returnDate === null
                                                ? "Issue Date : " +
                                                  new Date(
                                                      item.issueDate
                                                  ).toDateString()
                                                : "Returned Date : " +
                                                  new Date(
                                                      item.returnDate
                                                  ).toDateString()}
                                        </Typography>

                                        <Typography variant="body2">
                                            Duration :{" "}
                                            {new Date(
                                                item.duration
                                            ).toDateString()}
                                        </Typography>

                                        <Typography variant="body2">
                                            Operator : {item.operator.name}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Grid>
                    <Grid item>
                        <TablePagination
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            count={count}
                            rowsPerPage={pagination.limit}
                            component="div"
                            page={pagination.page}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default BookHistory;
