import {
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { FeeStructureService } from "../../services/FeeStructureService";
import { AddViewContext } from "../AddView";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";

type Props = {
    row?: any;
    updateMode?: boolean;
    duplicateMode?: boolean;
};

const AddFeeStructure = ({ row, updateMode, duplicateMode }: Props) => {
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const { feedback, setFeedback, user } = useContext(AppContext);
    const [filter, setFilter] = useState({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus.id,
        classroom: "",
        session: "",
    });

    const [filterCopy, setFilterCopy] = useState({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus.id,
        classroom: "",
        session: "",
    });
    const [feeStructure, setFeeStructure] = useState<any>({});
    const [feeCategory, setFeeCategory] = useState([]);
    const [feeStructureCopy, setFeeStructureCopy] = useState([]);
    const theme = useTheme();

    const handleChange = (ev: any) => {
        setFeeStructure({
            ...feeStructure,
            [ev.target.name]: {
                checked: feeStructure[ev.target.name].checked,
                amount: ev.target.value,
            },
        });
    };

    const handleCheckboxChange = (ev: any, checked: boolean) => {
        setFeeStructure({
            ...feeStructure,
            [ev.target.name]: {
                amount: feeStructure[ev.target.name].amount,
                checked,
            },
        });
    };

    const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setFeedback({
            ...feedback,
            loading: true,
        });

        const categories = Object.entries(feeStructure)
            .filter((entry: any[]) => entry[1].checked)
            .map((entry: any[]) => ({
                amount: entry[1].amount,
                id: parseInt(entry[0]),
            }));

        const finalObject = {
            categories,
            classroom: filter.classroom,
            session: filter.session,
            status: feeStructure.status,
        };

        if (!updateMode) {
            const [data, err] = await FeeStructureService.createFeeStructure(
                finalObject
            );

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });

                setOpen(false);
                triggerRowsRefetch();
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else if (duplicateMode) {
            const [data, err] = await FeeStructureService.createFeeStructure(
                finalObject
            );

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });

                setOpen(false);
                triggerRowsRefetch();
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else {
            const hasChangeInFilter =
                JSON.stringify(filter) != JSON.stringify(filterCopy);
            const hasChangeInFeeStructure =
                JSON.stringify(feeStructure) !=
                JSON.stringify(feeStructureCopy);

            if (!hasChangeInFeeStructure && !hasChangeInFilter) {
                setFeedback({
                    loading: false,
                    message:
                        "Cannot update fee Structure when there is no change.",
                    severity: "error",
                    show: true,
                });
                return;
            }

            const [data, err] = await FeeStructureService.editFeeStructure(
                row.id,
                finalObject
            );

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });

                setOpen(false);
                triggerRowsRefetch();
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    useEffect(() => {
        if (updateMode && row?.id) {
            const currentFeeStructureCats = row.categories.reduce(
                (prev: any, cat: any) => ({
                    ...prev,
                    [cat?.category?.id]: {
                        amount: cat.amount,
                        checked: true,
                    },
                }),
                {}
            );

            setFeeCategory(row.categories.map((item: any) => item.category));
            setFeeStructure({
                ...feeStructure,
                ...currentFeeStructureCats,
                status: row.status,
            });
            setFeeStructureCopy({
                ...feeStructure,
                ...currentFeeStructureCats,
                status: row.status,
            });
            setFilter({
                campus: row.classroom.campus.id,
                classroom: row.classroom.id,
                session: row.session.id,
            });
            setFilterCopy({
                campus: row.classroom.campus.id,
                classroom: row.classroom.id,
                session: row.session.id,
            });
        }
    }, [updateMode]);

    useEffect(() => {
        if (duplicateMode) {
            const currentFeeStructureCats = row.categories.reduce(
                (prev: any, cat: any) => ({
                    ...prev,
                    [cat?.category?.id]: {
                        amount: cat.amount,
                        checked: true,
                    },
                }),
                {}
            );

            setFeeCategory(row.categories.map((item: any) => item.category));
            setFeeStructure({
                ...feeStructure,
                ...currentFeeStructureCats,
                status: row.status,
            });
            setFeeStructureCopy({
                ...feeStructure,
                ...currentFeeStructureCats,
            });
            setFilter({
                campus: row.classroom.campus.id,
                classroom: row.classroom.id,
                session: row.session.id,
            });
            setFilterCopy({
                campus: row.classroom.campus.id,
                classroom: row.classroom.id,
                session: row.session.id,
            });
        }
    }, [duplicateMode]);

    const getButtonLabel = () => {
        if (duplicateMode) {
            return feedback.loading
                ? "Duplicating fee structure..."
                : "Duplicate fee structure";
        }

        if (updateMode) {
            return feedback.loading
                ? "Updating fee structure..."
                : "Update fee structure";
        }

        return feedback.loading
            ? "Adding fee structure..."
            : "Add fee structure";
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item container xs={12} spacing={2}>
                    {user.baseUser.userRegions.length > 1 && (
                        <Grid item xs={12}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                defaultValue={
                                    updateMode || duplicateMode
                                        ? row.classroom.campus
                                        : undefined
                                }
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Autocomplete
                            key={updateMode ? filter.session : undefined}
                            api="/org/session"
                            label="Session"
                            labelKey="name"
                            textFieldProps={{ size: "small", required: true }}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                            setOutput={(session) =>
                                setFilter({ ...filter, session: session.id })
                            }
                            defaultValue={row ? row.session : undefined}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            key={updateMode ? filter.classroom : undefined}
                            api="/org/academics/classroom"
                            label="Class"
                            labelKey="name"
                            textFieldProps={{ size: "small", required: true }}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                            setOutput={(classroom) =>
                                setFilter({
                                    ...filter,
                                    classroom: classroom.id,
                                })
                            }
                            defaultValue={
                                updateMode || duplicateMode
                                    ? row.classroom
                                    : undefined
                            }
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        api="/org/fees/category"
                        labelKey="name"
                        label="Fee Categories"
                        multiple
                        textFieldProps={{
                            size: "small",
                            required: !feeCategory.length,
                        }}
                        defaultValue={
                            updateMode || duplicateMode
                                ? row.categories.map(
                                      (item: any) => item.category
                                  )
                                : undefined
                        }
                        setOutput={(cats) => {
                            setFeeCategory(cats);

                            setFeeStructure(
                                cats.reduce(
                                    (prev: any, curr: any) => ({
                                        ...prev,
                                        [curr.id]: feeStructure[curr.id]
                                            ? feeStructure[curr.id]
                                            : {
                                                  checked: true,
                                                  amount: "",
                                              },
                                    }),
                                    {}
                                )
                            );
                        }}
                        apiParams={{ status: "active" }}
                    />
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                    // maxHeight={240}
                    // sx={{ overflowY: "scroll" }}
                    // justifyContent="flex-start"
                    flexDirection="column"
                    // border="1px solid"
                >
                    {feeCategory.map((fc: any, idx) => (
                        <Grid
                            item
                            container
                            alignItems="center"
                            key={idx}
                            spacing={2}
                            // border="1px solid red"
                        >
                            <Grid
                                item
                                xs={6}
                                display="flex"
                                alignItems="center"
                            >
                                <Checkbox
                                    onChange={handleCheckboxChange}
                                    checked={feeStructure[fc?.id]?.checked}
                                    name={fc.id + ""}
                                />

                                <Typography
                                    color={
                                        !feeStructure[fc.id].checked
                                            ? "gray"
                                            : "contrastColor"
                                    }
                                >
                                    {fc.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    disabled={!feeStructure[fc.id].checked}
                                    size="small"
                                    type="number"
                                    focused={feeStructure[fc.id].checked}
                                    placeholder="Enter fee i.e. 1000"
                                    inputProps={{ min: 0 }}
                                    defaultValue={
                                        updateMode || duplicateMode
                                            ? row.categories.amount
                                            : "undefined"
                                    }
                                    value={feeStructure[fc.id].amount}
                                    name={fc.id + ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        setOutput={(c) =>
                            setFeeStructure({
                                ...feeStructure,
                                status: c?.id || "",
                            })
                        }
                        label="Status"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        defaultOptions={[
                            { id: "active", name: "Active" },
                            { id: "inactive", name: "Inactive" },
                        ]}
                        defaultValue={
                            updateMode || duplicateMode
                                ? {
                                      id: row?.status,
                                      name: row?.status,
                                  }
                                : { id: "active", name: "Active" }
                        }
                    />
                </Grid>

                <Grid item xs={12} display="flex" gap={theme.spacing(2)}>
                    <Button
                        sx={{ flex: 1 }}
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {/* {!updateMode
                            ? feedback.loading
                                ? "Adding fee structure..."
                                : "Add fee structure"
                            : feedback.loading
                            ? "updating fee structure..."
                            : "update fee structure"} */}
                        {getButtonLabel()}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddFeeStructure;
