import { CompareArrows } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import BookIssue from "./BookIssue";
import BookReturn from "./BookReturn";

const ManageIssues = ({ row }: any) => {
    console.log(row);
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("issue");

    const handleTabChange = (e: any, value: any) => {
        setActiveTab(value);
    };

    return (
        <>
            <IconButton onClick={() => setOpen(true)} size="small">
                <CompareArrows fontSize="small" />
            </IconButton>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogContent>
                    <Tabs
                        value={activeTab}
                        onChange={(e, value) => handleTabChange(e, value)}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="issue" label="Issue" />

                        <Tab value="return" label="Return" />
                    </Tabs>

                    {activeTab === "issue" ? (
                        <BookIssue
                            row={row}
                            campus={row.campus.id}
                            open={open}
                            setOpen={setOpen}
                        />
                    ) : (
                        <BookReturn row={row} open={open} setOpen={setOpen} />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ManageIssues;
