import { Close, Edit } from "@mui/icons-material";
import {
    AppBar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Toolbar,
} from "@mui/material";
import { useState } from "react";
import AddBook from "./AddBook";

const EditBook = ({ row, changes, setChanges }: any) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} size="small">
                <Edit fontSize="small" />
            </IconButton>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullScreen
                TransitionComponent={Slide}
            >
                <DialogTitle>
                    <AppBar>
                        <Toolbar
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>Edit Book Info</div>

                            <IconButton onClick={() => setOpen(false)}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent>
                    <br /> <br /> <br />
                    <AddBook
                        editMode={true}
                        rowToEdit={row}
                        _open={open}
                        _setOpen={setOpen}
                        changes={changes}
                        setChanges={setChanges}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EditBook;
