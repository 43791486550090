import { AddCircle, Attachment, MenuBook } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Modal,
    TextField,
} from "@mui/material";
import isEqual from "lodash/isEqual";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { GET } from "../../../services/BaseService";
import { BookService } from "../../../services/BookService";
import { S3Service } from "../../../services/S3Service";
import AddPublisher from "../../Academics/Publisher/AddPublisher";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { BaseBreadcrum } from "../../BaseBreadcrum";
import { awsDirStruct } from "../../constants";
import AddAuthor from "../Author/AddAuthor";
import AddGenre from "../Genre/AddGenre";
import { CustomFields } from "./type";

type Filter = {
    campus: number;
    author: number[];
    publisher: number;
    genres: number[];
};

type Props = {
    editMode: boolean;
    rowToEdit: any;
    _open: any;
    _setOpen: any;
    changes: any;
    setChanges: any;
};

const AddBook = ({
    editMode,
    rowToEdit,
    _open,
    _setOpen,
    changes,
    setChanges,
}: Props) => {
    const history = useHistory();

    const { user, feedback, setFeedback } = useContext(AppContext);

    let pdf: any;
    let img: any;

    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus.id,
        author: [],
        publisher: "",
        genres: [],
    });
    const [sourceTypes, setSourceTypes] = useState([]);
    const [bindings, setBindings] = useState([]);
    const [bookTypes, setBookTypes] = useState([]);
    const [showDialog, setShowDialog] = useState({
        addAuthor: false,
        addPublisher: false,
        addGenre: false,
        addCustomFields: false,
    });

    const [open, setOpen] = useState(false);

    const [book, setBook] = useState<any>({
        name: "",
        place: "",
        price: "",
        year: new Date().getFullYear(),
        description: "",
        pages: "",
        cellNo: "",
        spine: "",
        opts: "",
        source: "",
        binding: "",
        type: "",
        publisher: "",
        author: [],
        genres: [],
        quantity: 0,
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus.id,
    });

    console.log(user?.baseUser?.userRegions[0]?.campus.id);

    const [bookCopy, setBookCopy] = useState<any>({
        name: "",
        place: "",
        price: "",
        year: new Date().getFullYear(),
        description: "",
        pages: "",
        cellNo: "",
        spine: "",
        opts: "",
        source: "",
        binding: "",
        type: "",
        publisher: "",
        author: [],
        genres: [],
        quantity: 0,
    });

    const [customFields, setCustomFields] = useState<CustomFields>({
        0: { label: "", value: "" },
    });

    const [attachments, setAttachments] = useState<any>({
        bookImage: "",
        pdf: "",
    });

    const [attachmentsCopy, setAttachmentsCopy] = useState<any>({
        bookImage: "",
        pdf: "",
    });

    // ===========================================================
    const getSourceTypes = async () => {
        const [data, err] = await GET("/library/book/source-types");

        data ? setSourceTypes(data.rows) : setSourceTypes([]);
    };

    const getBookTypes = async () => {
        const [data, err] = await GET("/library/book/types");

        data ? setBookTypes(data.rows) : setBookTypes([]);
    };

    const getBindings = async () => {
        const [data, err] = await GET("/library/book/binding-types");

        data ? setBindings(data.rows) : setBindings([]);
    };

    // ==============================================================
    const handleChange = (e: any) => {
        setBook({ ...book, [e.target.name]: e.target.value });
    };

    const handleAdditionalInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAttachments({
            ...attachments,
            [e.target.name]: e.target.files![0],
        });
    };

    const addCustomRow = () => {
        const newRowId = Object.keys(customFields).length;

        setCustomFields({
            ...customFields,
            [newRowId]: { label: "", value: "" },
        });
    };

    const handleCustomFieldsChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        id: number
    ) => {
        setCustomFields({
            ...customFields,
            [id]: { ...customFields[id], [e.target.name]: e.target.value },
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let anotherFeedback = feedback;

        setFeedback({ ...feedback, loading: true });

        const hasChangesinBook =
            JSON.stringify(book) !== JSON.stringify(bookCopy);
        const hasChangesinAttachments = !isEqual(attachments, attachmentsCopy);

        if (!hasChangesinBook && editMode && !hasChangesinAttachments) {
            setFeedback({
                loading: false,
                message: "Cannot update book when there is no change.",
                severity: "error",
                show: true,
            });
            return;
        }

        const _book = {
            ...book,
            campus: book.campus,
            opts: JSON.stringify(
                Object.values(customFields).filter(
                    (field: any) =>
                        field.label.length > 0 && field.value.length > 0
                )
            ),
        };

        if (hasChangesinBook && editMode) {
            const [data, err] = await BookService.editBook(rowToEdit.id, {
                ..._book,
                quantity: undefined,
            });

            if (data) {
                anotherFeedback = {
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                };
                history.replace("/library/books");
                _setOpen(false);
                setChanges(changes + 1);
            } else {
                anotherFeedback = {
                    show: true,
                    message: err,
                    severity: "error",
                    loading: false,
                };
            }
        }

        if (hasChangesinAttachments && editMode) {
            const bookImagekey =
                awsDirStruct.library.bookPictures + "/" + rowToEdit.id;
            const bookPdfkey =
                awsDirStruct.library.bookPdfs + "/" + rowToEdit.id;

            if (
                attachments.bookImage !== undefined &&
                attachments.pdf !== undefined
            ) {
                try {
                    await Promise.all([
                        S3Service.uploadToS3(
                            bookImagekey,
                            attachments.bookImage
                        ),
                        S3Service.uploadToS3(bookPdfkey, attachments.pdf),
                    ]).then((values) => console.log(values));

                    anotherFeedback = {
                        message: "File  uploaded  successfully.",
                        show: true,
                        severity: "success",
                        loading: false,
                    };
                    history.replace("/library/books");
                    _setOpen(false);
                } catch (error) {
                    anotherFeedback = {
                        message: "File uploading failed.",
                        show: true,
                        severity: "success",
                        loading: false,
                    };
                }
            } else if (attachments.bookImage !== undefined) {
                try {
                    console.log("Enters this block ");
                    await S3Service.uploadToS3(
                        bookImagekey,
                        attachments.bookImage
                    );

                    anotherFeedback = {
                        message: "Book image  uploaded  successfully.",
                        show: true,
                        severity: "success",
                        loading: false,
                    };
                    history.replace("/library/books");
                    _setOpen(false);
                } catch (error) {
                    anotherFeedback = {
                        message: "book image uploading failed",
                        show: true,
                        severity: "success",
                        loading: false,
                    };
                }
            } else if (attachments.pdf !== undefined) {
                try {
                    await S3Service.uploadToS3(bookPdfkey, attachments.pdf);
                    anotherFeedback = {
                        message: "Book pdf uploaded successfully",
                        show: true,
                        severity: "success",
                        loading: false,
                    };

                    history.replace("/library/books");
                    _setOpen(false);
                } catch (error) {
                    anotherFeedback = {
                        message: "Book pdf uploading failed",
                        show: true,
                        severity: "success",
                        loading: false,
                    };
                    history.replace("/library/books");
                    _setOpen(false);
                }
            } else {
                console.log("Error while uploading book image");
            }
        }

        if (!editMode) {
            const [data, err] = await BookService.createBook(_book);
            if (data) {
                const bookImagekey =
                    awsDirStruct.library.bookPictures + "/" + data.book.id;
                const bookPdfkey =
                    awsDirStruct.library.bookPdfs + "/" + data.book.id;

                if (
                    attachments.bookImage !== undefined &&
                    attachments.pdf !== undefined
                ) {
                    await Promise.all([
                        S3Service.uploadToS3(
                            bookImagekey,
                            attachments.bookImage
                        ),
                        S3Service.uploadToS3(bookPdfkey, attachments.pdf),
                    ]).then((values) => console.log(values));
                } else if (attachments.bookImage !== undefined) {
                    await S3Service.uploadToS3(
                        bookImagekey,
                        attachments.bookImage
                    );
                } else if (attachments.pdf !== undefined) {
                    await S3Service.uploadToS3(bookPdfkey, attachments.pdf);
                } else {
                    console.log("Error while uploading book image");
                }

                anotherFeedback = {
                    ...feedback,
                    show: true,
                    message: data.message,
                    severity: "success",
                    loading: false,
                };
                setFeedback(anotherFeedback);
                history.replace("/library/books");
            } else {
                anotherFeedback = {
                    show: false,
                    message: err,
                    severity: "error",
                    loading: false,
                };
                setFeedback(anotherFeedback);
            }
        }

        setFeedback(anotherFeedback);
    };

    useEffect(() => {
        getSourceTypes();
        getBookTypes();
        getBindings();
    }, []);

    const getBookImages = async () => {
        const bookImagesSrcs = [];
        const [bookImages, bookImagesErr] = await BookService.getBook(1, 0, {
            id: rowToEdit.id,
        });

        if (bookImages) {
            for (const book of bookImages.rows) {
                const key = `${awsDirStruct.library.bookPictures}/${rowToEdit.id}`;
                const [data, err] = await S3Service.getS3ByKey(key);

                if (data) {
                    bookImagesSrcs.push(data.url);
                }

                // Await fetch here so `img` is set before proceeding
                const res = await fetch(bookImagesSrcs.slice(-1)[0]);
                if (res.status === 200) {
                    img = await res.blob();
                }
            }
        }
    };

    const getBookPdf = async () => {
        const bookPdfSrcs = [];
        const [bookPdfs, bookPdfsErr] = await BookService.getBook(1, 0, {
            id: rowToEdit.id,
        });

        if (bookPdfs) {
            for (const book of bookPdfs.rows) {
                const key = `${awsDirStruct.library.bookPdfs}/${book.id}`;
                const [data, err] = await S3Service.getS3ByKey(key);

                if (data) {
                    bookPdfSrcs.push(data.url);
                }

                // Await fetch here so `pdf` is set before proceeding
                const res = await fetch(bookPdfSrcs.slice(-1)[0]);
                if (res.status === 200) {
                    pdf = await res.blob();
                }
            }
        }
    };

    // Combine both fetch operations to update state after both complete
    useEffect(() => {
        const fetchAttachments = async () => {
            await getBookImages();
            await getBookPdf();

            // Ensure img and pdf are set before updating state
            setAttachments({
                bookImage: img,
                pdf: pdf,
            });

            setAttachmentsCopy({
                bookImage: img,
                pdf: pdf,
            });
        };

        if (rowToEdit?.id) {
            fetchAttachments();
        }
    }, [editMode]);

    useEffect(() => {
        if (!editMode) return;

        setBook({
            ...book,
            name: rowToEdit.name,
            place: rowToEdit.place,
            price: rowToEdit.price,
            year: rowToEdit.year,
            description: rowToEdit.description,
            pages: rowToEdit.pages,
            cellNo: rowToEdit.cellNo,
            spine: rowToEdit.spine,
            source: rowToEdit.source,
            binding: rowToEdit.binding,
            type: rowToEdit.type,
            author: rowToEdit.authorsDetail.map((item: any) => {
                return { id: item.id, name: item.name };
            }),
            genres: rowToEdit._genres.map((genre: any) => genre.id),
            publisher: rowToEdit.publisherDetail.id,
        });

        setBookCopy({
            ...book,
            name: rowToEdit.name,
            place: rowToEdit.place,
            price: rowToEdit.price,
            year: rowToEdit.year,
            description: rowToEdit.description,
            pages: rowToEdit.pages,
            cellNo: rowToEdit.cellNo,
            spine: rowToEdit.spine,
            source: rowToEdit.source,
            binding: rowToEdit.binding,
            type: rowToEdit.type,
            author: rowToEdit.authorsDetail.map((item: any) => {
                return { id: item.id, name: item.name };
            }),
            genres: rowToEdit._genres.map((genre: any) => genre.id),
            publisher: rowToEdit.publisherDetail.id,
        });
    }, [editMode]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                {!editMode && (
                    <BaseBreadcrum
                        links={[
                            {
                                linkName: "Books",
                                linkUrl: "/library/books",
                            },

                            {
                                linkName: "Add Book",
                                linkUrl: "/add-book",
                            },
                        ]}
                    />
                )}

                <Grid container spacing={1}>
                    {!editMode && user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12}>
                            <Autocomplete
                                api="/org/campus"
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{ size: "medium" }}
                                setOutput={(opt) => {
                                    if (!opt) return;
                                    setBook({
                                        ...book,
                                        campus: opt.id,
                                    });
                                }}
                                apiParams={{ status: "active" }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Book Title"
                            name="name"
                            fullWidth
                            value={book.name}
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            multiline
                            minRows={3}
                            variant="outlined"
                            label="Book Description"
                            name="description"
                            fullWidth
                            value={book.description}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Price"
                            name="price"
                            value={book.price}
                            type="number"
                            required
                            onChange={(e) =>
                                setBook({
                                    ...book,
                                    price:
                                        parseInt(e.target.value) < 0
                                            ? "0"
                                            : e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Pages"
                            name="pages"
                            value={book.pages}
                            type="number"
                            onChange={(e) =>
                                setBook({
                                    ...book,
                                    pages:
                                        parseInt(e.target.value) < 0
                                            ? "1"
                                            : e.target.value,
                                })
                            }
                            // InputProps={{ startAdornment: <Typography>Rs. </Typography> }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <Autocomplete
                            api="/library/author"
                            label="Author"
                            labelKey="name"
                            textFieldProps={{ size: "medium" }}
                            multiple
                            defaultValue={editMode && rowToEdit?.authorsDetail}
                            setOutput={(opt) => {
                                if (!opt) return;
                                setBook({
                                    ...book,
                                    author: opt.map((item: any) => item.id),
                                });
                            }}
                            apiParams={{ status: "active" }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <Autocomplete
                            api="/library/genres"
                            label="Genres"
                            labelKey="name"
                            multiple
                            defaultValue={editMode && rowToEdit?._genres}
                            setOutput={(opt) => {
                                if (!opt) return;
                                setBook({
                                    ...book,
                                    genres: opt.map((item: any) => item.id),
                                });
                            }}
                            apiParams={{ status: "active" }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <Autocomplete
                            api="/org/academics/publisher"
                            label="Publisher"
                            labelKey="name"
                            defaultValue={
                                editMode && rowToEdit?.publisherDetail
                            }
                            setOutput={(opt) => {
                                if (!opt) return;
                                setBook({
                                    ...book,
                                    publisher: opt.id,
                                });
                            }}
                            apiParams={{ status: "active" }}
                        />
                    </Grid>

                    {/* {!editMode && (
                        <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                            <SearchableInput
                                api={`${baseAPI}/library/author`}
                                label={"Author"}
                                size="medium"
                                InputProps={{
                                    startAdornment: editMode ? (
                                        <></>
                                    ) : (
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            onClick={() =>
                                                setShowDialog({
                                                    ...showDialog,
                                                    addAuthor: true,
                                                })
                                            }
                                        >
                                            <AddCircle />
                                        </IconButton>
                                    ),
                                }}
                                filter={filter}
                                setFilter={setFilter}
                                invalidate={showDialog.addAuthor}
                                multiple={true}
                                defaultValue={
                                    editMode
                                        ? rowToEdit.authorsDetail
                                        : undefined
                                }
                            />
                        </Grid>
                    )}

                    {!editMode && (
                        <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                            <SearchableInput
                                api={`${baseAPI}/org/academics/publisher`}
                                label={"Publisher"}
                                size="medium"
                                InputProps={{
                                    startAdornment: editMode ? (
                                        <></>
                                    ) : (
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            onClick={() =>
                                                setShowDialog({
                                                    ...showDialog,
                                                    addPublisher: true,
                                                })
                                            }
                                        >
                                            <AddCircle />
                                        </IconButton>
                                    ),
                                }}
                                filter={filter}
                                setFilter={setFilter}
                                invalidate={showDialog.addPublisher}
                                defaultValue={
                                    editMode
                                        ? rowToEdit.publisherDetail
                                        : undefined
                                }
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    )}

                    {!editMode && (
                        <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                            <SearchableInput
                                api={`${baseAPI}/library/genres`}
                                label={"Genres"}
                                size="medium"
                                InputProps={{
                                    startAdornment: editMode ? (
                                        <></>
                                    ) : (
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            onClick={() =>
                                                setShowDialog({
                                                    ...showDialog,
                                                    addGenre: true,
                                                })
                                            }
                                        >
                                            <AddCircle />
                                        </IconButton>
                                    ),
                                }}
                                filter={filter.author}
                                setFilter={setFilter}
                                invalidate={showDialog.addGenre}
                                multiple={true}
                                defaultValue={
                                    editMode ? rowToEdit._genres : undefined
                                }
                                _name="genres"
                            />
                        </Grid>
                    )} */}

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            variant="outlined"
                            label="Place of Publisher"
                            name="place"
                            fullWidth
                            value={book.place}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            variant="outlined"
                            label="Year"
                            name="year"
                            fullWidth
                            value={book.year}
                            onChange={(e) =>
                                setBook({
                                    ...book,
                                    year: parseInt(e.target.value) || 0,
                                })
                            }
                            type="text"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            select
                            variant="outlined"
                            label="Binding"
                            name="binding"
                            fullWidth
                            value={book.binding}
                            onChange={handleChange}
                            required
                        >
                            {bindings.map((binding: any) => (
                                <MenuItem
                                    value={binding.type}
                                    key={binding.type}
                                >
                                    {binding.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            select
                            variant="outlined"
                            label="Source"
                            name="source"
                            fullWidth
                            value={book.source}
                            onChange={handleChange}
                            required
                        >
                            {sourceTypes.map((sourceType: any) => (
                                <MenuItem
                                    key={sourceType.type}
                                    value={sourceType.type}
                                >
                                    {sourceType.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            select
                            variant="outlined"
                            label="Book Type"
                            name="type"
                            fullWidth
                            value={book.type}
                            onChange={handleChange}
                            required
                        >
                            {bookTypes.map((bookType: any) => (
                                <MenuItem
                                    key={bookType.type}
                                    value={bookType.type}
                                >
                                    {bookType.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            variant="outlined"
                            label="Classification / Cell No"
                            name="cellNo"
                            fullWidth
                            value={book.cellNo}
                            onChange={handleChange}
                            type="text"
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={editMode ? 3 : 4} xl={3}>
                        <TextField
                            variant="outlined"
                            label="Sprine Label"
                            name="spine"
                            fullWidth
                            value={book.spine}
                            onChange={handleChange}
                            type="text"
                        />
                    </Grid>

                    {customFields[0].label.length > 0 &&
                        customFields[0].value.length > 0 && (
                            <Grid item xs={12} my={1}>
                                <Divider>Custom Fields</Divider>
                            </Grid>
                        )}

                    {Object.values(customFields).map(
                        (item: any, id: number) =>
                            item.label.length > 0 &&
                            item.value.length > 0 && (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                    key={id}
                                >
                                    <TextField
                                        label={item.label}
                                        value={item.value}
                                        fullWidth
                                    />
                                </Grid>
                            )
                    )}

                    <Grid item xs={12} my={1}>
                        <Divider>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}
                            >
                                <Attachment />
                                Attachments
                            </div>
                        </Divider>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Book Image"
                            type="file"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ accept: "image/*" }}
                            name="bookImage"
                            onChange={handleAdditionalInfoChange}
                            defaultValue={attachments.bookImage}
                        />

                        {editMode && attachments && attachments.bookImage && (
                            <img
                                src={
                                    attachments.bookImage &&
                                    URL.createObjectURL(attachments.bookImage)
                                }
                                alt="Book Image Preview"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginTop: "10px",
                                }}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="PDF / Ebook"
                            type="file"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ accept: ".pdf" }}
                            name="pdf"
                            onChange={handleAdditionalInfoChange}
                            defaultValue={attachments.pdf}
                        />

                        {editMode && attachments && attachments?.pdf && (
                            <div style={{ padding: "1rem" }}>
                                {attachments && attachments?.pdf ? (
                                    <IconButton onClick={() => setOpen(true)}>
                                        <MenuBook fontSize="large" />
                                    </IconButton>
                                ) : (
                                    <MenuBook fontSize="large" />
                                )}

                                <Modal
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    sx={{
                                        height: "90vh",
                                        width: "90%",
                                        margin: "auto",
                                    }}
                                >
                                    <iframe
                                        src={
                                            attachments &&
                                            attachments.pdf &&
                                            URL.createObjectURL(attachments.pdf)
                                        }
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            border: 0,
                                        }}
                                    ></iframe>
                                </Modal>
                            </div>
                        )}
                    </Grid>

                    {!editMode && (
                        <>
                            <Grid item xs={12} my={2}>
                                <Divider role="presentation">Stock</Divider>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={book.quantity}
                                    label="Initial Stock"
                                    name="quantity"
                                    type="number"
                                    onChange={(e) =>
                                        setBook({
                                            ...book,
                                            quantity: parseInt(e.target.value),
                                        })
                                    }
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <Button
                            sx={{ height: "100%" }}
                            variant="outlined"
                            size="large"
                            type="submit"
                            disabled={feedback.loading}
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1em" />
                                ) : null
                            }
                        >
                            {!editMode
                                ? feedback.loading
                                    ? "adding book..."
                                    : "add book"
                                : feedback.loading
                                ? "editing book..."
                                : "edit book"}
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <Dialog
                open={showDialog.addPublisher}
                fullWidth
                onClose={() =>
                    setShowDialog({ ...showDialog, addPublisher: false })
                }
            >
                <DialogTitle>Add Publisher</DialogTitle>

                <DialogContent>
                    <AddPublisher />
                </DialogContent>
            </Dialog>

            <Dialog
                open={showDialog.addAuthor}
                fullWidth
                onClose={() =>
                    setShowDialog({ ...showDialog, addAuthor: false })
                }
            >
                <DialogTitle>Add Author</DialogTitle>

                <DialogContent>
                    <AddAuthor dialogView />
                </DialogContent>
            </Dialog>

            <Dialog
                open={showDialog.addGenre}
                fullWidth
                onClose={() =>
                    setShowDialog({ ...showDialog, addGenre: false })
                }
            >
                <DialogTitle>Add Genre</DialogTitle>

                <DialogContent>
                    <AddGenre dialogView />
                </DialogContent>
            </Dialog>

            <Dialog
                open={showDialog.addCustomFields}
                fullWidth
                onClose={() =>
                    setShowDialog({ ...showDialog, addCustomFields: false })
                }
            >
                <DialogTitle>Add Custom Fields</DialogTitle>

                <DialogContent>
                    <Grid container py={1} spacing={1}>
                        {Object.entries(customFields).map(([id, row]) => (
                            <Grid item container xs={11} spacing={1} key={id}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Label"
                                        value={row.label}
                                        name="label"
                                        onChange={(e) =>
                                            handleCustomFieldsChange(
                                                e,
                                                parseInt(id)
                                            )
                                        }
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Value"
                                        value={row.value}
                                        name="value"
                                        onChange={(e) =>
                                            handleCustomFieldsChange(
                                                e,
                                                parseInt(id)
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        ))}

                        <Grid item xs={1} alignSelf="center">
                            <IconButton onClick={addCustomRow}>
                                <AddCircle />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddBook;
