import { ClearAll } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { SessionService } from "../../../services/SessionService";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import ManageIssues from "./ManageIssues";

type Filter = {
    campus: number | string;
    type: "employee" | "student" | "user" | string;
    student?: {
        name?: string;
        enrollmentNo?: string;
        fileNo?: string;
        username?: string;
    };
    employee?: { name?: string; fileNo?: string; username?: string };
    user?: { name?: string; username?: string };

    [key: string]: any;
};

const IssueReturn = () => {
    const { user } = useContext(AppContext);
    const [sort, setSort] = useState<{ column: ""; direction: "" }>();
    const [lastSession, setLastSession] = useState<any>();

    const [filter, setFilter] = useState<Filter>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,

        session: "",
        classroom: "",
        section: "",
        type: "student",
        student: {},
        employee: {},
        user: {},
        search: "",

        apply: 0,
    });

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus:
                user?.baseUser?.userRegions?.length > 1
                    ? ""
                    : user?.baseUser?.userRegions[0]?.campus?.id,
            type: "student",
            student: {},
            employee: {},
            user: {},
            search: "",
            apply: 0,
        });
    };

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: filter.campus,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: "",
                }));
                setLastSession(null);
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (filter.campus) {
            LastSession();
        }
    }, [filter.campus]);

    return (
        <Grid container spacing={1}>
            {/* <Grid item>
                <Typography variant="h4" color="primary" gutterBottom>
                    Issue&nbsp;&&nbsp;Return
                </Typography>
            </Grid> */}

            {/* <Grid item xs={12} py={2}>
                <FormControl required>
                    <FormLabel>Issue book to</FormLabel>

                    <RadioGroup
                        row
                        value={filter?.type}
                        onChange={(e) =>
                            setFilter({ ...filter, type: e.target.value })
                        }
                    >
                        <FormControlLabel
                            value="student"
                            control={<Radio />}
                            label="Student"
                        />

                        <FormControlLabel
                            value="employee"
                            control={<Radio />}
                            label="Employee"
                            disabled
                        />

                        <FormControlLabel
                            value="user"
                            control={<Radio />}
                            label="User"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid> */}

            {user?.baseUser?.userRegions?.length > 1 && (
                <Grid item container xs={12} md={4}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c: any) =>
                        setFilter({
                            ...filter,
                            session: c?.id || "",
                        })
                    }
                    label="Session"
                    labelKey="name"
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                    }}
                    clear={clearFilters}
                    apiParams={{
                        campus: filter.campus,
                        status: "active",
                    }}
                    defaultValue={lastSession}
                    key={lastSession?.id}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c: any) =>
                        setFilter({
                            ...filter,
                            classroom: c?.id || "",
                        })
                    }
                    label="Class"
                    labelKey="name"
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                    }}
                    clear={clearFilters}
                    apiParams={{
                        campus: filter.campus,
                        status: "active",
                    }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c: any) =>
                        setFilter({
                            ...filter,
                            section: c?.id || "",
                        })
                    }
                    label="Section"
                    labelKey="name"
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                    }}
                    clear={clearFilters}
                    apiParams={{
                        classroom: filter.classroom,
                        status: "active",
                    }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Search
                    filter={filter}
                    setFilter={setFilter}
                    clear={clearFilters}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    onClick={() =>
                        setFilter({
                            ...filter,
                            apply: filter.apply + 1,
                        })
                    }
                    style={{ height: "100%" }}
                    size="small"
                >
                    {" "}
                    Search
                </Button>
            </Grid>

            <Grid item xs={12} md={4}>
                <Tooltip title="Clear Filters">
                    <IconButton onClick={handleFilterClear} color="primary">
                        <ClearAll />
                    </IconButton>
                </Tooltip>
            </Grid>

            <Grid item xs={12}>
                <RUDTable
                    getter={
                        filter.type === "student"
                            ? (page, limit) =>
                                  StudentService.getStudent(page, limit, {
                                      campus: filter.campus
                                          ? filter.campus
                                          : undefined,

                                      classroom: filter.classroom
                                          ? filter.classroom
                                          : undefined,

                                      session: filter.session
                                          ? filter.session
                                          : undefined,

                                      section: filter.section
                                          ? filter.section
                                          : undefined,

                                      search: filter.search
                                          ? filter.search
                                          : undefined,
                                      column: sort?.column
                                          ? sort.column
                                          : undefined,
                                      direction: sort?.direction
                                          ? sort.direction
                                          : undefined,
                                  })
                            : async () => []
                    }
                    readables={
                        filter.type === "student"
                            ? {
                                  id: "ID",
                                  admissionNumber: "Reg No.",
                                  enrollmentNo: "Enroll No.",
                                  fileNo: "File No.",
                                  name: "Name",
                                  fatherName: "Father Name",
                                  ManageissueReturn: "Manage Issues / Returns",
                              }
                            : {}
                    }
                    rowsPreprocessor={(row) =>
                        filter.type === "student"
                            ? {
                                  ...row,
                                  name: row.baseUser.name,
                                  fatherName: row.fatherName,
                                  id: row.id,
                                  admissionNumber: row.admissionNumber,
                              }
                            : { ...row }
                    }
                    updatingAgents={[filter.apply, sort?.direction]}
                    customCols={[
                        {
                            header: user?.baseUser?.ops.includes(
                                "UPDATE LIBRARY_HISTORY"
                            )
                                ? "ManageissueReturn"
                                : "",
                            content: (row) => (
                                <MultiPermissionAuthorize
                                    ops={["UPDATE LIBRARY_HISTORY"]}
                                >
                                    <ManageIssues row={row} />
                                </MultiPermissionAuthorize>
                            ),
                        },
                    ]}
                    ops={{
                        read: "READ LIBRARY_HISTORY",
                        edit: "",
                        delete: "DELETE LIBRARY_HISTORY",
                    }}
                    storageKey="issue-Return"
                    onSort={(row: any) => {
                        setSort(row);
                    }}
                    unsortableCols={["ManageissueReturn"]}
                />
            </Grid>
        </Grid>
    );
};

export default IssueReturn;
