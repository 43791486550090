import { CheckCircle } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { usePostAPI } from "../../../services/Hooks";
import { AppContext } from "../../AppContext";
import { baseAPI } from "../../constants";

const ReactivateUser = ({
    rows,
    disabled,
    changes,
    setChanges,
    onSuccess,
}: any) => {
    const history = useHistory();

    const { feedback, setFeedback } = useContext(AppContext);

    const [
        reactivateStudent,
        reactivateStudentResponse,
        reactivateStatus,
        reactivateMessage,
    ] = usePostAPI(baseAPI + "/org/student/reactivate-student");

    async function handleReactivateStudent() {
        reactivateStudent(
            {
                students: rows.map((item: any) => item.id),
                admississionStatus: "confirmed",
            },
            {}
        );
    }

    useEffect(() => {
        if (["idle", "loading"].includes(reactivateStatus)) return;

        setFeedback({
            ...feedback,
            respneseDetail:
                reactivateStudentResponse && reactivateStudentResponse,
            show: true,
            severity: reactivateStatus == "success" ? "success" : "error",
            message: reactivateMessage,
        });

        if (reactivateStatus == "success" && reactivateMessage) {
            history.replace("/student");
            setChanges((c: any) => c + 1);
        }
    }, [
        reactivateStatus,
        history,
        reactivateMessage,
        reactivateStudentResponse,
    ]);
    return (
        <div>
            <Tooltip title="Reactivate  Student">
                <IconButton
                    onClick={() => handleReactivateStudent()}
                    disabled={feedback.loading || disabled}
                >
                    {feedback.loading ? (
                        <CircularProgress size="1rem" />
                    ) : (
                        <CheckCircle />
                    )}
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default ReactivateUser;
