import { Add, ClearAll } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { BookService } from "../../../services/BookService";
import { StockService } from "../../../services/StockService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import EditStock from "./EditStock";
import Instances from "./Instances";

const Stock = () => {
    const { user } = useContext(AppContext);
    const [clearFilters, setClearFilters] = useState(false);
    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
    });

    const [sort, setSort] = useState<{ column: ""; direction: "" }>();

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({ search: "", status: "" });
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Stock
                    </Typography>
                </Grid>

                <MultiPermissionAuthorize ops={["CREATE BOOK_INSTANCE"]}>
                    <Grid>
                        <Link
                            to="/library/stock/add"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                startIcon={<Add />}
                                variant="outlined"
                                color="primary"
                                style={{ height: "100%" }}
                            >
                                Add Stock
                            </Button>
                        </Link>
                    </Grid>
                </MultiPermissionAuthorize>
            </Grid>

            <BaseAccordian>
                <Grid container spacing={1}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={10} md={6} lg={3}>
                        {" "}
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            search
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    BookService.getBook(page, limit, {
                        ...filter,
                        column: sort?.column || undefined,
                        direction: sort?.direction || undefined,
                    })
                }
                editor={StockService.editStock}
                rowsPreprocessor={(row) => {
                    const bookInstances = row.bookInstance || [];
                    const stockCount = bookInstances.length || 0;
                    const IssueCount =
                        bookInstances.filter(
                            (item: any) => item?.status === "issued"
                        ).length || 0;

                    const goodCondition =
                        bookInstances.filter(
                            (item: any) => item?.condition === "ok"
                        ).length || 0;

                    const lostCondition =
                        bookInstances.filter(
                            (item: any) => item?.condition === "lost"
                        ).length || 0;

                    const damagedCondition =
                        bookInstances.filter(
                            (item: any) => item?.condition === "damaged"
                        ).length || 0;

                    const remainingStockCount = stockCount - IssueCount;
                    return {
                        ...row,
                        deletionId: row.id,
                        campusName: bookInstances[0]?.campus?.name || "N/A",
                        campus: {
                            id: bookInstances[0]?.campus?.id,
                            name: bookInstances[0]?.campus?.name,
                        },
                        StockCount: stockCount,
                        IssueCount: IssueCount,
                        remainingStockCount: remainingStockCount,
                        goodCondition: goodCondition,
                        lostCondition: lostCondition,
                        damagedCondition: damagedCondition,
                    };
                }}
                readables={{
                    id: "ID",
                    name: "Book Name",
                    campusName: "Campus Name",
                    StockCount: "Total Book",
                    IssueCount: "Issue Book",
                    remainingStockCount: "Remaining Book",
                    goodCondition: "Condition(Ok)",
                    lostCondition: "Condition(Lost)",
                    damagedCondition: "Condition(Damaged)",
                    Stock: "Stock",
                    Edit: "Edit",
                }}
                customCols={[
                    {
                        header: "Stock",
                        content: (row) => <Instances row={row} />,
                    },
                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE BOOK_INSTANCE"
                        )
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE BOOK_INSTANCE"]}
                            >
                                <EditStock row={row} />
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, sort?.direction]}
                ops={{
                    read: "READ BOOK_INSTANCE",
                    edit: "",
                    delete: "DELETE BOOK_INSTANCE",
                }}
                storageKey="stock"
                onSort={(row: any) => {
                    setSort(row);
                }}
                unsortableCols={["Stock", "Edit", "campusName", "Stock Count"]}
            />
        </div>
    );
};

export default Stock;
