import { Edit } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { StockService } from "../../../services/StockService";

import { useHistory } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { baseAPI } from "../../constants";
import SearchableInput from "../../SearchableInput";

const EditStock = ({ row }: any) => {
    console.log(row);
    const { user } = useContext(AppContext);
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState<any>({
        book: row.id,
        target: row.StockCount,
        campus: row.campus,
    });
    const [feedback, setFeedback] = useState<any>({
        hidden: true,
        severity: "",
        message: "",
        loading: false,
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setFeedback({
            ...feedback,
            loading: true,
        });

        const [data, err] = await StockService.updateStockQuantity({
            target: filter.target,
            campus: row.campus.id,
            book: row.id,
        });

        if (data) {
            setFeedback({
                hidden: false,
                message: data.message,
                severity: "success",
                loading: false,
            });
            const timer = setTimeout(() => {
                setOpen(false);
            }, 3000);
        } else {
            setFeedback({
                hidden: false,
                message: err,
                severity: "error",
                loading: false,
            });
        }
    };

    useEffect(() => {
        return () => {
            setFeedback({
                hidden: true,
                message: "",
                severity: "",
                loading: false,
            });
        };
    }, []);

    useEffect(() => {
        setFilter({ book: row.id, target: row.StockCount, campus: row.campus });
    }, [row]);

    return (
        <>
            <IconButton
                onClick={() => {
                    setFilter({ ...filter, getCount: true });
                    setOpen(true);
                }}
                size="small"
            >
                <Edit fontSize="small" />
            </IconButton>

            <Dialog
                open={open}
                onClose={() => {
                    setFeedback({ hidden: true });
                    setFilter({ ...filter, target: undefined });
                    setOpen(false);
                }}
                TransitionComponent={Slide}
                fullWidth
            >
                <DialogTitle>{row.name}</DialogTitle>

                <DialogContent>
                    <Grid
                        container
                        py={1}
                        spacing={1}
                        component="form"
                        onSubmit={handleSubmit}
                    >
                        {!feedback.hidden && (
                            <Grid item xs={12}>
                                <Alert security={feedback.severity}>
                                    {feedback.message}
                                </Alert>
                            </Grid>
                        )}

                        {/* <Grid item xs={12}>
                            <SearchableInput
                                api={`${baseAPI}/org/campus`}
                                label="Campus"
                                _name="campus"
                                filter={filter}
                                setFilter={setFilter}
                                multiple={false}
                                required={true}
                            />
                        </Grid> */}
                        {user.baseUser.userRegions.length > 1 && (
                            <Grid xs={12} item>
                                <SearchableInput
                                    api={`${baseAPI}/org/campus`}
                                    label="Campus"
                                    filter={filter}
                                    setFilter={setFilter}
                                    _name="campus"
                                    required
                                    defaultValue={row.campus}
                                    disabled
                                />{" "}
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <TextField
                                label="Quantity"
                                type="number"
                                name="target"
                                size="small"
                                required
                                disabled={
                                    !Boolean(filter.campus) ? true : false
                                }
                                InputProps={{
                                    endAdornment: feedback.loading ? (
                                        <CircularProgress size="1rem" />
                                    ) : null,
                                }}
                                value={filter.target}
                                inputProps={{ min: 0 }}
                                fullWidth
                                onChange={(e) =>
                                    setFilter({
                                        ...filter,
                                        target: e.target.value,
                                    })
                                }
                            />
                        </Grid>

                        <Grid xs={12} item mt={1}>
                            <Button
                                variant="outlined"
                                type="submit"
                                disabled={feedback.loading}
                                endIcon={
                                    feedback.loading ? (
                                        <CircularProgress size="1rem" />
                                    ) : null
                                }
                            >
                                edit stock
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EditStock;
