import { Percent, Search } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { ClassroomService } from "../../services/ClassroomService";
import { FeeDiscountService } from "../../services/FeeDiscountService";
import { FeeStructureService } from "../../services/FeeStructureService";
import { StudentService } from "../../services/StudentService";
import { AddViewContext } from "../AddView";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";

type Props = {
    row?: any;
    updateMode?: boolean;
};
const AddFeeDiscount = ({ row, updateMode }: Props) => {
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const searchbtnRef = useRef<any>();

    const [feeDiscount, setFeeDiscout] = useState<any>({
        discountType: "",
        targetId: "",
        pct: 0,
        amount: 0,
        category: "",
        status: "",
    });

    const [feeDiscountCopy, setFeeDiscoutCopy] = useState<any>({
        discountType: "",
        targetId: "",
        pct: 0,
        amount: 0,
        category: "",
    });

    const [discountUnit, setDiscountUnit] = useState<"amount" | "pct">(
        "amount"
    );

    const { feedback, setFeedback, user } = useContext(AppContext);
    const [discountTypes, setDiscountTypes] = useState([]);
    const [classrooms, setClassrooms] = useState<null | {
        id: number;
        name: string;
    }>(null);
    const [tooltipTitle, setTooltipTitle] = useState<any>("");
    const [feeCategories, setFeeCategories] = useState<any>();
    const [target, setTarget] = useState<any>(null);

    const getDiscountTypes = async () => {
        const [data, err] = await FeeDiscountService.discountTypes();

        if (err) {
            setFeedback({
                ...feedback,
                severity: "error",
                message: err,
                show: true,
            });
        } else {
            setDiscountTypes(data.rows);
        }
    };

    const getClassrooms = async () => {
        const [data, err] = await ClassroomService.getClassroom(1, 100, {
            id: updateMode ? feeDiscount.targetId : undefined,
        });
        if (data) {
            setClassrooms(data.rows[0]);
        }
    };

    const handleChange = (ev: any) => {
        let value = ev.target.value;

        if (value && value > 0) {
            if (ev.target.name === "amount") {
                const targetCategoryMaxAmount = feeCategories.filter(
                    (cat: any) => cat.category.id === feeDiscount.category
                )[0].amount;
                value = Math.min(targetCategoryMaxAmount, parseFloat(value));

                console.log(ev.target.name, value);
                setFeeDiscout({ ...feeDiscount, amount: value, pct: 0 });
                return;
            } else if (ev.target.name === "pct") {
                value = Math.max(0, Math.min(parseFloat(value), 100));

                console.log(ev.target.name, value);
                setFeeDiscout({ ...feeDiscount, amount: 0, pct: value });
                return;
            }
        }

        const amountNPct =
            ev.target.name === "category" ? { amount: 0, pct: 0 } : {};

        setFeeDiscout({
            ...feeDiscount,
            [ev.target.name]: value,
            ...amountNPct,
        });
    };

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        if (!updateMode) {
            const [data, err] = await FeeDiscountService.createFeeDiscount({
                ...feeDiscount,
                targetId: feeDiscount._targetId
                    ? feeDiscount._targetId
                    : feeDiscount.targetId,
            });

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });

                setOpen(false);
                triggerRowsRefetch();
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else {
            const hasChanges =
                JSON.stringify(feeDiscount) !== JSON.stringify(feeDiscountCopy);

            if (!hasChanges) {
                setFeedback({
                    loading: false,
                    message:
                        "Cannot update fee discount when there is no change.",
                    severity: "error",
                    show: true,
                });
                return;
            }
            const [data, err] = await FeeDiscountService.editFeeDiscount(
                row.id,
                {
                    ...feeDiscount,
                    targetId: feeDiscount._targetId
                        ? feeDiscount._targetId
                        : feeDiscount.targetId,
                    _targetId: undefined,
                }
            );

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });

                setOpen(false);
                triggerRowsRefetch();
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    const verifyStudentFileno = async () => {
        const [data, err] = await StudentService.getStudent(1, 1, {
            fileNo: feeDiscount.targetId,
        });

        if (data) {
            if (data.rows.length > 0) {
                setTooltipTitle(
                    <Alert severity="success" sx={{ mb: 1, p: 1 }}>
                        <AlertTitle>User found</AlertTitle>
                        <Typography variant="body1" fontWeight="bold">
                            {data.rows[0].baseUser.name}
                        </Typography>
                    </Alert>
                );
                setFeeDiscout({
                    ...feeDiscount,
                    _targetId: data.rows[0].id,
                    amount: updateMode ? row.amount : 0,
                    pct: 0,
                });
                setTarget(data.rows[0]);

                const [feeStructData, _] =
                    await FeeStructureService.getFeeStructure(1, 1, {
                        classroom: data.rows[0].classroom.id,
                        session: data.rows[0].session.id,
                    });

                if (feeStructData && feeStructData.rows.length > 0) {
                    setFeeCategories(feeStructData.rows[0].categories);
                }
            } else {
                setTooltipTitle(
                    <Alert severity="error" sx={{ mb: 1, p: 1 }}>
                        <AlertTitle>User not found</AlertTitle>
                    </Alert>
                );
            }
        }
    };

    const getClassroomCategories = async () => {
        const [data, err] = await FeeStructureService.getFeeStructure(1, 1, {
            classroom: feeDiscount.targetId,
        });

        if (data && data.rows.length > 0) {
            setFeeCategories(data.rows[0].categories);
        }
    };

    useEffect(() => {
        getDiscountTypes();
    }, []);

    useEffect(() => {
        if (feeDiscount.discountType === "classroom") {
            getClassrooms();
            setTooltipTitle("");
        } else if (feeDiscount.discountType === "student") {
            setTooltipTitle(
                <Alert severity="info" sx={{ mb: 1, p: 1 }}>
                    <AlertTitle>Enter a student File number</AlertTitle>
                </Alert>
            );
        }
    }, [feeDiscount.discountType]);

    useEffect(() => {
        if (feeDiscount.discountType === "classroom" && feeDiscount.targetId) {
            getClassroomCategories();
        }
    }, [feeDiscount.targetId]);

    useEffect(() => {
        if (updateMode) {
            setFeeDiscout({
                discountType: row.discountType,
                category: row.category.id,
                pct: row.pct === "-" ? 0 : row?.pct.split("%")[0],
                targetId: row.targetId,
                amount: row.amount === "-" ? 0 : row.amount,
                status: row.status,
            });

            setFeeDiscoutCopy({
                discountType: row.discountType,
                category: row.category.id,
                pct: row.pct === "-" ? 0 : row?.pct.split("%")[0],
                targetId: row.targetId,
                amount: row.amount === "-" ? 0 : row.amount,
                status: row.status,
            });

            if (row.pct != "-") {
                setDiscountUnit("pct");
            }

            if (searchbtnRef.current) {
                searchbtnRef.current.click();
            }
            getClassrooms();
        }
    }, [updateMode, row?.id, searchbtnRef.current]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Discount Type"
                        value={feeDiscount.discountType}
                        onChange={handleChange}
                        name="discountType"
                        fullWidth
                        select
                    >
                        {discountTypes.map((dt: any) => (
                            <MenuItem key={dt.type} value={dt.type}>
                                {dt.type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {feeDiscount.discountType && (
                    <Grid item xs={12}>
                        {feeDiscount.discountType === "classroom" ? (
                            <Autocomplete
                                key={classrooms?.id}
                                api="/org/academics/classroom"
                                labelKey="name"
                                setOutput={(item) =>
                                    setFeeDiscout({
                                        ...feeDiscount,
                                        targetId: item.id,
                                    })
                                }
                                textFieldProps={{ size: "small" }}
                                label="Classroom"
                                defaultValue={
                                    updateMode && classrooms
                                        ? classrooms
                                        : undefined
                                }
                                apiParams={{ status: "active" }}
                            />
                        ) : (
                            <Tooltip
                                title={tooltipTitle}
                                placement="top"
                                components={{ Tooltip: "div" }}
                            >
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    label="Student file no"
                                    value={feeDiscount.targetId}
                                    onChange={handleChange}
                                    name="targetId"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={verifyStudentFileno}
                                                ref={searchbtnRef}
                                            >
                                                <Search />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TextField
                        size="small"
                        name="category"
                        value={feeDiscount.category}
                        onChange={handleChange}
                        select
                        fullWidth
                        variant="outlined"
                        label="Fee category to discount in"
                        defaultValue={updateMode && row.category.id}
                    >
                        {feeCategories?.map((cat: any) => (
                            <MenuItem
                                key={cat.category.id}
                                value={cat.category.id}
                            >
                                {cat.category.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label={
                            discountUnit === "pct" ? "Amount in %" : "Amount"
                        }
                        value={
                            discountUnit === "pct"
                                ? feeDiscount.pct
                                : feeDiscount.amount
                        }
                        onChange={handleChange}
                        name={discountUnit}
                        disabled={!feeDiscount.category}
                        type="number"
                        fullWidth
                        inputProps={{ min: 0 } as any}
                        InputProps={{
                            startAdornment: (
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setDiscountUnit(
                                            discountUnit === "amount"
                                                ? "pct"
                                                : "amount"
                                        )
                                    }
                                >
                                    {discountUnit === "pct" ? (
                                        <Percent fontSize="small" />
                                    ) : (
                                        <Typography>Rs</Typography>
                                    )}
                                </IconButton>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        setOutput={(c) =>
                            setFeeDiscout({
                                ...feeDiscount,
                                status: c?.id || "",
                            })
                        }
                        label="Status"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        defaultOptions={[
                            { id: "active", name: "Active" },
                            { id: "inactive", name: "Inactive" },
                        ]}
                        defaultValue={
                            updateMode
                                ? {
                                      id: row?.status,
                                      name: row?.status,
                                  }
                                : { id: "active", name: "Active" }
                        }
                    />
                </Grid>

                <Grid item xs={12} mt={1}>
                    <Button
                        type="submit"
                        variant="outlined"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding Discount..."
                                : "Add Discount"
                            : feedback.loading
                            ? "Updating discount..."
                            : "Update discount"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddFeeDiscount;
