import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useGetAPI } from "../../../services/Hooks";
import { StockService } from "../../../services/StockService";
import { baseAPI } from "../../constants";

const BookReturn = ({ row, open, setOpen }: any) => {
    const [changeCount, setChangeCount] = useState(0);
    const [feedback, setFeedback] = useState<any>({
        hidden: true,
        message: "",
        severity: "",
        loading: false,
    });

    const [data, , status] = useGetAPI(
        `${baseAPI}/library/book-instance`,
        1,
        0,
        {
            student: row.id,
            status: "issued",
        },
        [changeCount]
    );

    const handleBookReturn = async (bookInstance: string) => {
        setFeedback({ ...feedback, loading: true });

        const [data, err] = await StockService.returnBook(bookInstance, {
            student: row?.id,
            returnDate: new Date(),
        });

        if (data) {
            setFeedback({
                hidden: false,
                message: data.message,
                severity: "success",
                loading: false,
            });

            setChangeCount(changeCount + 1);
            const timer = setTimeout(() => {
                setOpen(false);
            }, 1000);
        } else {
            setFeedback({
                hidden: false,
                message: err,
                severity: "error",
                loading: false,
            });
            const timer = setTimeout(() => {
                setOpen(false);
            }, 1000);
        }
    };

    return (
        <Grid container mt={1} spacing={1}>
            {!feedback.hidden && (
                <Grid item xs={12} mb={1}>
                    <Alert severity={feedback.severity}>
                        {feedback.message}
                    </Alert>
                </Grid>
            )}
            {status === "loading" ? (
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            ) : (
                data.map((row: any) => (
                    <Grid item container xs={12} key={row.id} spacing={1}>
                        <Grid item xs={12} md={5}>
                            <TextField
                                label="Book"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                value={`BK-${row.book.id}-${row.id}`}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <TextField
                                size="small"
                                fullWidth
                                label="Return Date"
                                InputLabelProps={{ shrink: true }}
                                value={new Date().toDateString()}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <Button
                                sx={{ height: "100%" }}
                                variant="outlined"
                                fullWidth
                                disabled={feedback.loading}
                                type="submit"
                                endIcon={
                                    feedback.loading ? (
                                        <CircularProgress size={"1rem"} />
                                    ) : null
                                }
                                onClick={() => handleBookReturn(row.id)}
                            >
                                {feedback.loading ? "returning..." : "return"}
                            </Button>
                        </Grid>
                    </Grid>
                ))
            )}

            <Grid item xs={12}>
                {data.length === 0 && status === "success" && (
                    <Typography variant="caption">
                        Books assigned to {row.baseUser.name} will appear here.
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default BookReturn;
