import { CircularProgress } from "@material-ui/core";
import { Box, Button, Grid, TextField } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import { SubjectService } from "../../../services/SubjectService";
import { AddViewContext } from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";

type publisher = {
    [id: number]: {
        name: string;
    };
};

const AddSubject = ({ row, updateMode = false }: any) => {
    const { feedback, setFeedback, user } = useContext(AppContext);
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const [filter, setFilter] = useState({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        classroom: "",
        publisher: "",
        status: "",
        name: "",
    });

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFeedback({ ...feedback, loading: true });

        const customFieldsComponent = {
            classroom: filter.classroom,
            publisher: filter.publisher,
            subjects: [{ name: filter.name }],
        };

        const [data, err] = await SubjectService.createSubject(
            customFieldsComponent
        );

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                show: true,
                severity: "success",
            });

            setOpen(false);
            triggerRowsRefetch();
        } else {
            setFeedback({
                loading: false,
                message: err,
                show: true,
                severity: "error",
            });
        }
    };

    return (
        <Box>
            <Grid
                container
                spacing={1.5}
                mt={1.5}
                component="form"
                onSubmit={handleSubmit}
            >
                <Grid item xs={12}>
                    <TextField
                        label="Name"
                        fullWidth
                        size="small"
                        name="name"
                        onChange={(e) =>
                            setFilter({ ...filter, name: e.target.value })
                        }
                    />
                </Grid>

                {/* <Grid item xs={12}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setFilter({
                                ...filter,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                </Grid> */}

                {user.baseUser.userRegions.length > 1 && (
                    <Grid item xs={12}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(c) =>
                            setFilter({
                                ...filter,
                                classroom: c?.id || "",
                            })
                        }
                        label="Class"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{ campus: filter.campus, status: "active" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        api="/org/academics/publisher"
                        setOutput={(p) =>
                            setFilter({
                                ...filter,
                                publisher: p?.id || "",
                            })
                        }
                        label="Publisher"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{ status: "active" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        setOutput={(c) =>
                            setFilter({
                                ...filter,
                                status: c?.id || "",
                            })
                        }
                        label="Status"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        defaultOptions={[
                            { id: "active", name: "Active" },
                            { id: "inactive", name: "Inactive" },
                        ]}
                        defaultValue={{
                            id: "active",
                            name: "Active",
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1rem" />
                            ) : null
                        }
                        fullWidth
                    >
                        {feedback.loading ? "adding subject..." : "add subject"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddSubject;
