import { ClearAll } from "@mui/icons-material";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { SessionService } from "../../../services/SessionService";
import { StudentAttendanceService } from "../../../services/StudentAttendanceService";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { dateFormatter } from "../../constants";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import AddStudentLeave from "./AddStudentLeave";
import StudentApplicationPreview from "./StudentApplicationPreview";
import StudentLeaveAction from "./StudentLeaveAction";

const StudentLeave = () => {
    const [changes, setChanges] = useState(0);
    const [showWithDelay, setShowWithDelay] = useState(false);
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [lastSession, setLastSession] = useState<any>();
    const [sort, setSort] = useState<{ direction: ""; column: "" }>();

    const [filter, setFilter] = useState<any>({
        search: "",
        attendanceDate: "",
        subject: "",
        user: user?.baseUser?.id,
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        classroom: "",
        type: "",
        session: "",
        section: "",
        startDate: new Date().toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
        attendanceStatus: "",
        apply: 0,
    });
    const [clearFilters, setClearFilters] = useState(false);
    const theme = useTheme();

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            attendanceDate: "",
            subject: "",
            user: user?.baseUser?.id,
            campus:
                user?.baseUser?.userRegions?.length > 1
                    ? ""
                    : user?.baseUser?.userRegions[0]?.campus?.id,
            classroom: "",
            type: "",
            session: "",
            section: "",
            attendanceStatus: "",
            apply: 0,
        });
    };

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: filter.campus ? filter.campus : null,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevData: any) => ({
                    ...prevData,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                console.warn("No session data found.");
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (filter.campus) {
            LastSession();
        }
    }, [filter?.campus as any]);

    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            // Start the delay timer
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500); // Adjust the delay time in milliseconds
        } else {
            // Reset visibility when feedback.show is false
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [feedback.show]);

    return (
        <div>
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h4" color="primary" gutterBottom>
                        Student Leave
                    </Typography>

                    <MultiPermissionAuthorize ops={["CREATE ATTENDANCE"]}>
                        <AddView
                            label="add student leave"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddStudentLeave />
                        </AddView>
                    </MultiPermissionAuthorize>
                </div>
            </div>

            <div>
                {/* <BaseAccordian > */}
                <Grid container spacing={1.5}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid xs={3} md={4}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={3} md={4}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    session: c.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                                required: true,
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                            key={lastSession?.id}
                            defaultValue={lastSession}
                        />
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <Autocomplete
                            api="/org/academics/section"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    section: c?.id || "",
                                })
                            }
                            label="Section"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                classroom: filter.classroom,
                                status: "active",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            label="From Date"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    startDate: e.target.value,
                                })
                            }
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={3} md={4}>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            label="To Date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    endDate: e.target.value,
                                })
                            }
                            variant="outlined"
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                {/* </BaseAccordian> */}
                <RUDTable
                    getter={(page, limit) =>
                        StudentAttendanceService.getLeaveStudent(page, limit, {
                            search: filter.search,
                            attendanceDate: filter.attendanceDate,
                            subject: filter.subject,
                            user: filter.user,
                            campus: filter.campus,
                            classroom: filter.classroom,
                            session: filter.session,
                            section: filter.section,
                            startDate: filter.startDate,
                            endDate: filter.endDate,
                            attendanceStatus: filter.attendanceStatus,
                            type: "dayWise",
                            column: sort?.column ? sort.column : undefined,
                            direction: sort?.direction
                                ? sort.direction
                                : undefined,
                        })
                    }
                    rowsPreprocessor={(row) => ({
                        ...row,
                        deletionId: row.id,
                        name: row.student.baseUser.name,
                        fatherName: row.student.fatherName,
                        fileNo: row.student.fileNo,
                        enrollmentNo: row.student.enrollmentNo,
                        campusName: row.campus.name,
                        classroomName: row.classroom.name,
                        admissionNumber: row.student.admissionNumber,
                        attendanceDate: dateFormatter.format(
                            new Date(row.attendanceDate)
                        ),
                        attendanceStatus:
                            row.attendanceStatus == "present" ? (
                                <Chip
                                    label={"Present"}
                                    size="small"
                                    color="success"
                                    style={{ width: "100%" }}
                                />
                            ) : row.attendanceStatus == "absent" ? (
                                <Chip
                                    label={"Absent"}
                                    size="small"
                                    color="error"
                                    style={{ width: "100%" }}
                                />
                            ) : row.attendanceStatus == "leave" ? (
                                <Chip
                                    label={"Leave"}
                                    size="small"
                                    color="primary"
                                    style={{ width: "100%" }}
                                />
                            ) : row.attendanceStatus == "pending" ? (
                                <Chip
                                    label={"Pending"}
                                    size="small"
                                    color="info"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                <Chip
                                    label={"Late"}
                                    size="small"
                                    color="warning"
                                    style={{ width: "100%" }}
                                />
                            ),
                        leaveStatus:
                            row.leaveStatus == "rejected" ? (
                                <Chip
                                    label={"Rejected"}
                                    size="small"
                                    color="error"
                                    style={{ width: "100%" }}
                                />
                            ) : row.leaveStatus == "accepted" ? (
                                <Chip
                                    label={"Accepted"}
                                    size="small"
                                    color="success"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                <Chip
                                    label={"Pending"}
                                    size="small"
                                    color="warning"
                                    style={{ width: "100%" }}
                                />
                            ),

                        applicationType: (
                            <Chip
                                label={row.applicationType}
                                size="small"
                                color="success"
                                style={{ width: "100%" }}
                            />
                        ),

                        operator: row.user.name,
                    })}
                    readables={{
                        admissionNumber: "Reg No.",
                        enrollmentNo: "Enroll No",
                        fileNo: "File No",
                        id: "ID",
                        name: "Name",
                        fatherName: "FatherName",
                        campusName: "Campus",
                        classroomName: "Class",
                        attendanceDate: "Attendance Date",
                        attendanceStatus: "Attendance Status",
                        leaveStatus: "Leave Status",
                        applicationType: "Application Type",
                        operator: "Operator",
                        remarks: "Remarks",
                        View: "View Application",
                        Action: "Action",
                        // Edit: "Edit",
                        // Changesequence: "Change Sequence",
                        // status: "Status",
                    }}
                    editables={[]}
                    customCols={[
                        {
                            header: "View",
                            content: (row) => (
                                <StudentApplicationPreview
                                    id={row.leaveApplication}
                                />
                            ),
                        },
                        {
                            header: "Action",
                            content: (row) => (
                                <StudentLeaveAction
                                    row={row}
                                    changes={changes}
                                    setChanges={setChanges}
                                />
                            ),
                        },
                    ]}
                    updatingAgents={[filter.apply, changes, sort?.direction]}
                    ops={{
                        read: "READ ATTENDANCE",
                        edit: "",
                        delete: "DELETE ATTENDANCE",
                    }}
                    storageKey="Student Leave"
                    onSort={(row: any) => {
                        setSort(row);
                    }}
                    unsortableCols={["View", "Action"]}
                />

                {showWithDelay && (
                    <FeedbackSnackbar
                        feedback={feedback}
                        setFeedback={setFeedback}
                    />
                )}
            </div>
        </div>
    );
};

export default StudentLeave;
