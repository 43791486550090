import { CircularProgress } from "@material-ui/core";
import { Add, Remove } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { ChangeEvent, FormEvent, Fragment, useContext, useState } from "react";
import { StudentCategoryService } from "../../../../services/StudentCategoryService";
import { AddViewContext } from "../../../AddView";
import { AppContext } from "../../../AppContext";
import { Autocomplete } from "../../../AutoComplete";

type studentcategory = {
    [id: number]: {
        name: string;
        status: "";
    };
};

const AddStudentCategory = () => {
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);

    const { feedback, setFeedback } = useContext(AppContext);
    const [fields, setFields] = useState<studentcategory>({
        0: {
            name: "",
            status: "",
        },
    });

    const handleChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        id: number
    ) => {
        setFields({
            ...fields,
            [id]: { ...fields[id], [e.target.name]: e.target.value },
        });
    };

    const handleRow = (variant: string, rowId = 0) => {
        if (variant === "add") {
            const newEntryId =
                parseInt(
                    Object.keys(fields)[Object.entries(fields).length - 1]
                ) + 1;

            setFields({ ...fields, [newEntryId]: { name: "", status: "" } });
        } else if (variant === "remove") {
            const updatedStock = Object.fromEntries(
                Object.entries(fields).filter(
                    ([id, stock]) => parseInt(id) !== rowId
                )
            );

            setFields(updatedStock);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFeedback({ ...feedback, loading: true });

        const customFieldsComponent = {
            category: Object.values(fields),
        };

        const [data, err] = await StudentCategoryService.createStudentCategory(
            customFieldsComponent
        );

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                show: true,
                severity: "success",
            });

            setOpen(false);
            triggerRowsRefetch();
        } else {
            setFeedback({
                loading: false,
                message: err,
                show: true,
                severity: "error",
            });
        }
    };

    return (
        <Box>
            <Grid
                container
                spacing={1.5}
                mt={1.5}
                component="form"
                onSubmit={handleSubmit}
            >
                {/* {feedback.show && (
                    <Grid item xs={12} mb={1.5}>
                        <Alert security={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    </Grid>
                )} */}

                {Object.entries(fields).map(
                    ([id, field]: any, count: number) => (
                        <Fragment key={id}>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    label="Name"
                                    fullWidth
                                    required
                                    size="small"
                                    name="name"
                                    onChange={(e) => handleChange(e, id)}
                                />
                            </Grid>

                            <Grid item xs={12} md={5}>
                                {" "}
                                <Autocomplete
                                    setOutput={(c) =>
                                        setFields((prevFields) => ({
                                            ...prevFields,
                                            [id]: {
                                                ...prevFields[id],
                                                status: c?.id || "",
                                            },
                                        }))
                                    }
                                    label="Status"
                                    labelKey="name"
                                    textFieldProps={{
                                        variant: "outlined",
                                        size: "small",
                                    }}
                                    defaultOptions={[
                                        { id: "active", name: "Active" },
                                        { id: "inactive", name: "Inactive" },
                                    ]}
                                    defaultValue={{
                                        id: "active",
                                        name: "Active",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={2} md={2}>
                                {parseInt(id) === 0 ? (
                                    <IconButton
                                        onClick={() => handleRow("add")}
                                        //   disabled={Object.values(fields).length >= 9}
                                    >
                                        <Add />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() =>
                                            handleRow("remove", parseInt(id))
                                        }
                                    >
                                        <Remove />
                                    </IconButton>
                                )}
                            </Grid>
                        </Fragment>
                    )
                )}

                <Grid item>
                    <Button
                        variant="outlined"
                        type="submit"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1rem" />
                            ) : null
                        }
                    >
                        {feedback.loading
                            ? "adding student category..."
                            : "add student category"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddStudentCategory;
