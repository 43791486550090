import { ClearAll } from "@mui/icons-material";
import {
    Button,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { SessionService } from "../../services/SessionService";
import { TestService } from "../../services/TestResultService";
import AddView from "../AddView";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { Search } from "../Filters/Search";
import { RUDTable } from "../Table/RUDTable";
import { dateFormatter } from "../constants";
import AddTestResult from "./AddTestResult";
import FeedbackSnackbar from "./FeedBackSnackBar";
import UpdateTestResultBulk from "./UpdateTestResultBulk";

const Test = () => {
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [sort, setSort] = useState<{ column: ""; direction: "" }>();
    const [changes, setChanges] = useState(0);
    const [filter, setFilter] = useState<any>({
        search: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        startDate: "",
        endDate: "",
        classroom: "",
        session: "",
        section: "",
        subject: [],
        id: "",
        apply: 0,
    });
    const [clearFilters, setClearFilters] = useState(false);
    const [showWithDelay, setShowWithDelay] = useState(false);
    const [lastSession, setLastSession] = useState<any>();
    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500);
        } else {
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer);
    }, [feedback.show]);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            campus: "",
            startDate: "",
            endDate: "",
            classroom: "",
            subject: [],
            session: "",
            apply: 0,
        });
    };
    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: filter.campus,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: "",
                }));
                setLastSession(null);
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (filter.campus) {
            LastSession();
        }
    }, [filter.campus]);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" color="primary" gutterBottom>
                    Test
                </Typography>

                <MultiPermissionAuthorize ops={["CREATE TEST_RESULT"]}>
                    <AddView
                        label="add test & result"
                        triggerRowsRefetch={() => setChanges(changes + 1)}
                        maxWidth="lg"
                    >
                        <AddTestResult />
                    </AddView>
                </MultiPermissionAuthorize>
            </div>

            <BaseAccordian>
                <Grid container spacing={1.5}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} lg={3}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} lg={3}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    session: c?.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                            defaultValue={lastSession}
                            key={lastSession?.id}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{ campus: filter.campus }}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Autocomplete
                            api="/org/academics/section"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    section: c?.id || "",
                                })
                            }
                            label="Section"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                classroom: filter.classroom,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Autocomplete
                            api="/org/Academics/Subject"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    subject: c?.id || "",
                                })
                            }
                            label="Subject"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{ classroom: filter.classroom }}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Autocomplete
                            api="/org/test-result/test"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    id: c?.id || "",
                                })
                            }
                            label="Test"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{ subject: filter.subject }}
                        />
                    </Grid>

                    <Grid item xs={4} lg={3}>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            label="Start Date"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    startDate: e.target.value,
                                })
                            }
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={4} lg={3}>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            label="End Date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    endDate: e.target.value,
                                })
                            }
                            variant="outlined"
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    TestService.getTest(page, limit, {
                        search: filter.search ? filter.search : null,
                        campus: filter.campus ? filter.campus : null,
                        startDate: filter.startDate ? filter.startDate : null,
                        endDate: filter.endDate ? filter.endDate : null,
                        subject: filter.subject ? filter.subject : null,
                        classroom: filter.classroom ? filter.classroom : null,
                        section: filter.section ? filter.section : null,
                        session: filter.session ? filter.session : null,
                        column: sort?.column ? sort.column : undefined,
                        direction: sort?.direction ? sort.direction : undefined,
                        id: filter.id ? filter.id : undefined,
                    })
                }
                deletor={
                    user?.baseUser?.ops.includes("DELETE TEST_RESULT")
                        ? (id: string | number) => TestService.deleteTest(id)
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    test: row.name,
                    testDate: dateFormatter.format(new Date(row.date)),
                    campusName: row.campus.name,
                    classroomName: row.classroom.name,
                    subjectName: row.subject.name,
                    employeeName: row.employee.name,
                    totalMarks: row.totalMarks,
                    sectionName: row.section.name,
                    _session: row.session,
                    _section: row.section,
                })}
                readables={{
                    test: "Name",
                    testDate: "Date",
                    totalMarks: "Total Marks",
                    campusName: "Campus Name",
                    classroomName: "Class",
                    subjectName: "Subject",
                    sectionName: "Section",
                    employeeName: "Employee",
                    Edit: "Edit",
                }}
                editables={[]}
                customCols={[
                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE TEST_RESULT"
                        )
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE TEST_RESULT"]}
                            >
                                <UpdateTestResultBulk
                                    row={row}
                                    change={changes}
                                    setChanges={setChanges}
                                />
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, changes, sort?.direction]}
                ops={{
                    read: "READ TEST_RESULT",
                    edit: "",
                    delete: "DELETE TEST_RESULT",
                }}
                storageKey="test"
                onSort={(row: any) => {
                    setSort(row);
                }}
                unsortableCols={["Edit"]}
            />

            {showWithDelay && (
                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            )}
        </div>
    );
};

export default Test;
