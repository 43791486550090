import { Add, Remove } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
} from "@mui/material";
import { ChangeEvent, FormEvent, Fragment, useMemo, useState } from "react";
import { BookIssueReturnService } from "../../../services/BookIssueReturnService";
import { Autocomplete } from "../../AutoComplete";

type Issues = {
    [id: number]: {
        duration: Date | string;
        issueDate: Date | string;
    };
};

const BookIssue = ({ row, campus, open, setOpen }: any) => {
    console.log(row);
    const [issues, setIssues] = useState<Issues>({
        0: {
            duration: "",
            issueDate: "",
        },
    });
    const [filter, setFilter] = useState<any>({ 0: "" });
    const [feedback, setFeedback] = useState<any>({
        hidden: true,
        severity: "",
        message: "",
        loading: false,
    });

    const handleRow = (variant: string, rowId = 0) => {
        if (variant === "add") {
            const newEntryId =
                parseInt(
                    Object.keys(issues)[Object.entries(issues).length - 1]
                ) + 1;

            setIssues({
                ...issues,
                [newEntryId]: { duration: "", issueDate: "" },
            });

            setFilter({ ...filter, [newEntryId]: "" });
        } else if (variant === "remove") {
            const updatedStock = Object.fromEntries(
                Object.entries(issues).filter(
                    ([id, stock]) => parseInt(id) !== rowId
                )
            );

            setIssues(updatedStock);

            const updatedFilter = Object.fromEntries(
                Object.entries(filter).filter(
                    ([id, book]) => parseInt(id) !== rowId
                )
            );

            setFilter(updatedFilter);
        }
    };

    console.log(row);

    const handleBookIssue = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const req = {
            student: row.id,
            book: Object.entries(filter).map(([k, v]: any) => ({
                bookInstance: v,
                issueDate: issues[k]?.issueDate,
                duration: issues[k]?.duration,
            })),
        };

        setFeedback({ ...feedback, loading: true });

        const [data, err] = await BookIssueReturnService.issueBook(req);

        if (data) {
            setFeedback({
                hidden: false,
                message: data.message,
                severity: "success",
                loading: false,
            });
            const timer = setTimeout(() => {
                setOpen(false);
            }, 1000);
        } else {
            setFeedback({
                hidden: false,
                message: err,
                severity: "error",
                loading: false,
            });

            const timer = setTimeout(() => {
                setOpen(false);
            }, 1000);
        }
    };

    const handleChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        id: number
    ) => {
        setIssues({
            ...issues,
            [id]: { ...issues[id], [e.target.name]: e.target.value },
        });
    };

    const duplicateEntry = useMemo(() => {}, [filter]);

    return (
        <Grid
            container
            mt={1}
            spacing={1}
            component="form"
            onSubmit={handleBookIssue}
        >
            {!feedback.hidden && (
                <Grid item xs={12} mb={1}>
                    <Alert severity={feedback.severity}>
                        {feedback.message}
                    </Alert>
                </Grid>
            )}

            {Object.entries(issues).map(([id, _stock]: any) => (
                <Fragment key={id}>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/library/book-instance"
                            setOutput={(selected: any) => {
                                const bookId = selected?.id || "";

                                setFilter((prevFilter: any) => {
                                    const updatedFilter = { ...prevFilter };
                                    const nextKey =
                                        Object.keys(updatedFilter).find(
                                            (key) => updatedFilter[key] === ""
                                        ) || "0";

                                    updatedFilter[nextKey] = bookId;

                                    return updatedFilter;
                                });
                            }}
                            label="Books"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                status: "available",
                                condition: "ok",
                                campus: row.campus.id,
                            }}
                            processor={(opt) => ({
                                ...opt,
                                name: `BK-${opt.id}  (${opt.book.name})`,
                            })}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Issue Date"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            type="date"
                            name="issueDate"
                            onChange={(e) => handleChange(e, id)}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Duration"
                            value={_stock?.duration}
                            inputProps={{ min: _stock?.issueDate }}
                            type="date"
                            required
                            name="duration"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            size="small"
                            onChange={(e) => handleChange(e, id)}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        {parseInt(id) === 0 ? (
                            <IconButton onClick={() => handleRow("add")}>
                                <Add />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={() =>
                                    handleRow("remove", parseInt(id))
                                }
                            >
                                <Remove />
                            </IconButton>
                        )}
                    </Grid>
                </Fragment>
            ))}

            <Grid item xs={12} mt={1}>
                <Button
                    variant="outlined"
                    type="submit"
                    disabled={feedback.loading}
                    endIcon={
                        feedback.loading ? (
                            <CircularProgress size={"1rem"} />
                        ) : null
                    }
                >
                    {feedback.loading ? "assigning books..." : "assign books"}
                </Button>
            </Grid>
        </Grid>
    );
};

export default BookIssue;
