import { ClearAll, ToggleOff } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { SessionService } from "../../../services/SessionService";
import { StudentService } from "../../../services/StudentService";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { dateFormatter } from "../../constants";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import FeedbackSnackbar from "../../TestResult/FeedBackSnackBar";
import { CompleteStudent } from "./CompleteStudent";
import ReactivateUser from "./ReactiveUser";

const ViewCompleteStudent = () => {
    const theme = useTheme();
    const [showWithDelay, setShowWithDelay] = useState(false);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [filter, setFilter] = useState<any>({
        search: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        status: "",
        apply: 0,
    });
    const [clearFilters, setClearFilters] = useState(false);
    const [order, setOrder] = useState<any>();
    const [lastSession, setLastSession] = useState<any>();

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            campus:
                user?.baseUser?.userRegions?.length > 1
                    ? ""
                    : user?.baseUser?.userRegions[0]?.campus?.id,
            status: "",
            apply: 0,
        });
    };

    const [changes, setChanges] = useState(0);

    const LastSession = async () => {
        try {
            const [data, err] = await SessionService.getLastSession(1, 1, {
                campus: filter.campus,
            });

            if (err) {
                console.error("Error fetching last session:", err);
                return;
            }

            if (data && data.rows.length) {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: data.rows[0].id,
                }));
                setLastSession(data.rows[0]);
            } else {
                setFilter((prevFilter: any) => ({
                    ...prevFilter,
                    session: "",
                }));
                setLastSession(null);
            }
        } catch (error) {
            console.error("Unexpected error in LastSession:", error);
        }
    };

    useEffect(() => {
        if (filter.campus) {
            LastSession();
        }
    }, [filter.campus]);

    useEffect(() => {
        let timer: any;
        if (feedback.show) {
            // Start the delay timer
            timer = setTimeout(() => {
                setShowWithDelay(true);
            }, 500); // Adjust the delay time in milliseconds
        } else {
            // Reset visibility when feedback.show is false
            setShowWithDelay(false);
        }

        return () => clearTimeout(timer); // Clean up the timer
    }, [feedback.show]);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" color="primary" gutterBottom>
                    Completed Students
                </Typography>

                <MultiPermissionAuthorize ops={["CREATE SESSION"]}>
                    <AddView
                        label="Add Completed Student"
                        triggerRowsRefetch={() => setChanges(changes + 1)}
                        maxWidth="lg"
                    >
                        <CompleteStudent />
                    </AddView>
                </MultiPermissionAuthorize>
            </div>

            <BaseAccordian>
                <Grid container spacing={1.5}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    session: c?.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                            defaultValue={lastSession}
                            key={lastSession?.id}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            api="/org/academics/section"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    section: c?.id || "",
                                })
                            }
                            label="Section"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                classroom: filter.classroom,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            defaultOptions={[
                                {
                                    id: "active",
                                    name: "Active",
                                },
                                {
                                    id: "inactive",
                                    name: "InActive",
                                },
                                {
                                    id: "withdrawn",
                                    name: "Withdrawn",
                                },
                            ]}
                            // api="/org/student/statuses"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    status: c?.id || "",
                                })
                            }
                            label="Status"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            defaultValue={{ id: "active", name: "Active" }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            label="Start Date"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    startDate: e.target.value,
                                })
                            }
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            label="End Date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    endDate: e.target.value,
                                })
                            }
                            variant="outlined"
                            defaultValue={
                                new Date().toISOString().split("T")[0]
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    StudentService.getStudent(page, limit, {
                        campus: filter.campus ? filter.campus : undefined,
                        session: filter.apply
                            ? filter.session
                                ? filter.session
                                : undefined
                            : undefined,
                        classroom: filter.classroom
                            ? filter.classroom
                            : undefined,
                        section: filter.section ? filter.section : undefined,
                        status: filter.status ? filter.status : undefined,
                        admissionStatus: "completed",
                        category: filter.category ? filter.category : undefined,
                        search: filter.search ? filter.search : undefined,
                        column: order.column ? order.column : undefined,
                        direction: order.direction
                            ? order.direction
                            : undefined,
                        startDate: filter.startDate
                            ? filter.startDate
                            : undefined,
                        endDate: filter.endDate ? filter.endDate : undefined,
                    })
                }
                editor={StudentService.editStudent}
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.baseUser.id,
                    id: row.id,
                    name: row.baseUser.name,
                    username: row.baseUser.username,
                    password: row.baseUser.password,
                    campusName: row.campus.name,
                    campusAddress: row.campus.address,
                    sessionStartTime: row.session.start,
                    sessionEndTime: row.session.end,
                    classroomName: row.classroom.name,
                    sectionName: row.section.name,
                    sessionName: row.session.name,
                    enrollmentNo: row.enrollmentNo || (
                        <Typography color={theme.palette.divider}>
                            N/A
                        </Typography>
                    ),
                    fileNo: row.fileNo || (
                        <Typography color={theme.palette.divider}>
                            N/A
                        </Typography>
                    ),
                    fatherMobile: row.fatherMobile || (
                        <Typography color={theme.palette.divider}>
                            N/A
                        </Typography>
                    ),
                    studentCNIC: row.studentCNIC || (
                        <Typography color={theme.palette.divider}>
                            N/A
                        </Typography>
                    ),
                    permanentAddress: row.permanentAddress || (
                        <Typography color={theme.palette.divider}>
                            N/A
                        </Typography>
                    ),
                    slcNo: row.slcNo || (
                        <Typography color={theme.palette.divider}>
                            N/A
                        </Typography>
                    ),
                    admissionNo: row.admissionNumber,
                    dob: dateFormatter.format(new Date(row.dob)),
                    admissionDate: dateFormatter.format(
                        new Date(row.admissionDate)
                    ),
                    admissionStatus: (
                        <Chip
                            variant="outlined"
                            icon={
                                row.admissionStatus === "completed" ? (
                                    <CheckIcon
                                        color="success"
                                        fontSize="small"
                                    />
                                ) : (
                                    <ToggleOff fontSize="small" color="error" />
                                )
                            }
                            label={row.admissionStatus}
                            sx={{ textTransform: "capitalize" }}
                            color={
                                row.admissionStatus === "completed"
                                    ? "success"
                                    : "error"
                            }
                            size="small"
                        />
                    ),
                })}
                readables={{
                    id: "ID",
                    admissionNo: "Reg No.",
                    enrollmentNo: "Enroll No",
                    fileNo: "File no",
                    name: "Name",
                    fatherName: "Father Name",
                    admissionStatus: "Admission Status",
                    classroomName: "Class",
                    sectionName: "Section",
                    sessionName: "Session",
                    fatherMobile: "Father Mobile",
                    studentCNIC: "Student CNIC",
                    currentAddress: "Current Address",
                    permanentAddress: "Permanent Address",
                    campusName: "Campus Name",
                    dob: "DOB",
                    gender: "Gender",
                    fatherCNIC: "Father Cnic",
                    slcNo: "Slc No",
                    admissionDate: "Admission Date",
                }}
                defaultSelected={[
                    "id",
                    "name",
                    "fatherName",
                    "gender",
                    "fileNo",
                ]}
                customCols={[]}
                updatingAgents={[filter.apply, changes, order?.direction]}
                ops={{
                    read: "READ STUDENT",
                    edit: "",
                    delete: "DELETE STUDENT",
                }}
                storageKey="students"
                onSort={(row) => {
                    setOrder(row);
                }}
                unsortableCols={[
                    "AddInfo",
                    "Edit",
                    "ViewStudent",
                    "ProfilePicture",
                    "ViewMore",
                    "_category",
                ]}
                getSelectedRows={(row) => {
                    setSelectedRows(row);
                }}
                actions={[
                    <ReactivateUser
                        rows={selectedRows}
                        disabled={selectedRows.length == 0}
                        changes={changes}
                        setChanges={setChanges}
                    />,
                ]}
            />

            {showWithDelay && (
                <FeedbackSnackbar
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            )}
        </div>
    );
};

export default ViewCompleteStudent;
